import { DeployEnvironment, NightingaleConfiguration } from "@chapter/nightingale-base";

const required = (env: Record<string, string | undefined>, key: string): string => {
    const value = env[key];
    if (value === undefined) {
        throw new Error(`Required environment variable ${key} missing.`);
    }
    return value;
};

export function buildNightingaleConfiguration(
    env: Record<string, string | undefined>
): NightingaleConfiguration {
    return {
        apiUrl: required(env, "NEXT_PUBLIC_API_URL"),
        datocms: {
            apiToken: required(env, "NEXT_PUBLIC_DATOCMS_API_TOKEN"),
            apiUrl: "https://graphql.datocms.com",
        },
        stytchPublicToken: required(env, "NEXT_PUBLIC_STYTCH_PUBLIC_TOKEN"),
        growthbook: {
            apiUrl: env.NEXT_PUBLIC_GROWTHBOOK_API_URL ?? "https://cdn.growthbook.io",
            clientKey: required(env, "NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY"),
            devMode: process.env.NODE_ENV !== "production",
        },
        expo: {},
        branchData: {
            branch: env.NEXT_PUBLIC_CODE_BRANCH,
            pullRequestUrl: env.NEXT_PUBLIC_CODE_PULL_REQUEST_URL,
        },
        ocr: {
            apiUrl: required(env, "NEXT_PUBLIC_OCR_API_URL"),
        },
        version: required(env, "NEXT_PUBLIC_APP_VERSION"),
        environment: required(env, "NEXT_PUBLIC_APP_ENV") as DeployEnvironment,
    };
}
